@import url(./font/stylesheet.css);

body {
  width: 100%;
  height: auto;
  margin: 0px auto !important;
  padding: 0 !important;
  font-family: "Acorn_r";
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2b2b2b;
  color: #525252;

  --black: #525252;
  --white: #fff;
  --green: #73b704;
  --orange: #ff6b01;
  --react-international-phone-font-size: .55em;
  --main_struct: 95%;
}

.react-international-phone-country-selector-button {
  border: none !important;
}

// * {
//   outline: none;
//   user-select: none;
// }

* {
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o--user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input {
  outline: none;
  -webkit-user-select: text;
  /* Chrome, Opera, Safari */
  -moz-user-select: text;
  /* Firefox 2+ */
  -ms-user-select: text;
  /* IE 10+ */
  user-select: text;
  /* Standard syntax */
}

*:not(.home_input_search__field)::placeholder {
  opacity: 0.3 !important;
}


main {
  background: white;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;

  .main_container {
    display: grid;
    place-items: center;
    width: 100%;
    height: auto;
    position: relative;
  }

  .main_struct {
    display: block;
    width: 95%;
    position: relative;
    // border: 1px solid;
  }

  .panierBox {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    background: #f8f8f8;
    z-index: 60;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;

    &__struct {
      display: block;
      width: var(--main_struct);

      padding-top: 20px;

      .perper {
        position: absolute;
        right: 2%;
        width: 35%;
        top: 0%;

        @media all and (min-width: 400px) {
          display: none;
        }
      }

      h2 {
        font-size: 1.875em;
        margin: 0 0;
        margin-bottom: 0.2em;
        margin-top: 0.5em;
      }

      h4 {
        color: #7e7e7e;
        margin: 0 0;
        font-size: 1.8em;
        font-weight: normal;
      }

      .BoxTotal {
        display: block;
        margin-top: 3em;

        .text1 {
          font-size: 1.125em;
          font-weight: 200;
          color: var(--green);
        }

        .value {
          font-size: 3.125em;
          font-weight: 600;
          color: var(--green);
          position: relative;
          display: inline-block;
          width: auto;

          &:after {
            content: attr(item);
            position: absolute;
            bottom: 0;
            right: 0%;
            transform: translateY(-65%) translateX(110%);
            font-weight: normal;
            color: var(--green);
            font-size: 0.3em;

            content: attr(devise);
          }
        }
      }
    }

    .panierList {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      background: var(--white);
      border-radius: 31px;
      height: auto;
      margin-top: 0em;
      margin-bottom: 20vh;

      &__struct {
        width: var(--main_struct);
        // border: 1px solid;
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        gap: 0.5em;

        .item {
          background: #f8f8f8;
          border-radius: 20px;
          width: 100%;
          padding-top: 10px;
          padding-bottom: 10px;
          display: grid;
          place-items: center;

          &__struct {
            width: 95%;
            display: flex;
            justify-content: flex-start;
            gap: 0.5em;
            align-items: center;

            position: relative;

            .nbre {
              background: var(--white);
              border-radius: 22px;
              width: 2.794375em;
              height: 2.096875em;
              color: var(--green);
              display: grid;
              place-items: center;
            }

            .item_name {
              font-size: 14px;
              font-weight: bold;

              width: 50%;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }

            .item_price {
              padding-left: 10px;
              padding-right: 10px;
              width: auto;
              line-height: 2.3em;
            }

            .delete {
              position: absolute;
              top: 50%;
              right: 0%;
              transform: translateY(-50%);

              background: #f7efea;
              border-radius: 50%;
              width: 30px;
              height: 30px;
              display: grid;
              place-items: center;

              svg {
                width: 40%;
              }
            }
          }

          .comment {
            display: grid;
            place-items: center;
            width: 100%;
            margin-top: .3em;

            &__struct {
              width: 90%;
              text-align: left;
              opacity: .9;
              font-size: .9em;
            }
          }
        }
      }
    }
  }

  .panierBoxAdmin {
    z-index: 100;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10em;
    width: calc(400px - 40px);
    position: fixed;
    left: auto;
    right: 0%;
    background-color: #e7f4d1;
    overflow: auto;
    display: block;

    border-radius: 30px;
    box-shadow: 0px 0px 10px 4px #00000021;

    @media all and (max-width: 400px) {
      width: calc(100% - 40px);
    }

    .btnAction {
      display: grid;
      place-items: center;
      width: 100%;
      height: 3.5em;
      font-size: 1.3em;
      border-radius: 30em;
      color: var(--green);
      background: transparent;
      margin-top: 0em;
      cursor: pointer;
      margin-top: 0.5em;
      box-sizing: border-box;
      border: 0.1em solid var(--green);

      &:hover {
        background: var(--green);
        color: white;
      }
    }

    .btnActionCancel {
      background: transparent;
      margin-top: 0.2em;
      margin-bottom: 2em;
      color: #d52d1a;
      border: 0.1em solid #d52d1a;

      &:hover {
        background: #d52d1a;
        color: white;
      }
    }

    .btnActionCancelDisable {
      background: #ececec;
      cursor: not-allowed;
    }

    .panierList {
      margin-bottom: 0;
    }

    &__struct {
      width: 80%;
    }
  }
}

.welcome_side {
  position: relative;
  margin-top: 20px;
  width: 100%;

  font-size: 1.25em;

  .g1 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column-reverse;
    margin-top: 4em;

    .t1 {
      color: var(--white);
    }

    .openTag {
      padding: 7px 10px;
      // margin-left: 0.5em;
      margin-bottom: 0.2em;
      flex-shrink: 0;
      border-radius: 15em;
      background: #e7f4d1;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--green);

      .text {
        font-size: 0.6em;
        line-height: 1em;
      }

      .dot {
        display: inline-block;
        width: 0.35rem;
        height: 0.35rem;
        flex-shrink: 0;
        border-radius: 50%;
        background: var(--green);
        margin-left: 0.3em;
        margin-right: 0.3em;
      }

      .ico svg {
        width: 0.7em;
        // transform: translateY(-10%);
      }
    }

    .close {
      background: #fef3f1;
      color: #d52d1a;

      .dot {
        background: #d52d1a;
      }

      .ico svg path {
        stroke: #d52d1a;
      }
    }
  }

  .resto_name {
    margin: 10px 0px;
    margin-bottom: 5px;
    margin-top: 0;
    color: var(--white);
    line-height: 1em;
    font-size: 2em;
    font-weight: 600;
    position: relative;
    z-index: 5;
    // text-shadow:0px 0px 1px black;
    display: inline-block;

    @media all and (min-width : 800px) {
      font-size: 7vw;
    }


  }

  // .resto_name {
  //   text-shadow: 0.03em 0.03em 0 hsl(25, 100%, 50%);
  // }
  // .resto_name:after {
  //   content: attr(data-shadow);
  //   position: absolute;
  //   top: 0.06em;
  //   left: 0.06em;
  //   z-index: -1;
  //   text-shadow: none;
  //   background-image: linear-gradient(
  //     45deg,
  //     transparent 45%,
  //     hsla(48, 20%, 90%, 1) 45%,
  //     hsla(48, 20%, 90%, 1) 55%,
  //     transparent 0
  //   );
  //   background-size: 0.05em 0.05em;
  //   -webkit-background-clip: text;
  //   -webkit-text-fill-color: transparent;

  //   animation: shad-anim 15s linear infinite;
  // }

  // @keyframes shad-anim {
  //   0% {
  //     background-position: 0 0;
  //   }
  //   0% {
  //     background-position: 100% -100%;
  //   }
  // }

  .bar_action {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 5;

    .call {
      width: 2.85rem;
      height: 2.85rem;
      border-radius: 50%;
      flex-shrink: 0;
      background: #e7f4d1;
      display: grid;
      place-items: center;

      .struct {
        width: 80%;
        height: 80%;
        border-radius: 50%;
        background: var(--green);
        display: grid;
        place-items: center;

        svg {
          width: 50%;
        }
      }
    }

    .location {
      height: 2.3rem;
      // width: 6rem;
      min-width: 8rem;
      ;
      max-width: calc(71% - 100px);
      border-radius: 30em;
      text-decoration: none;
      margin-left: 0.3em;

      background-color: #e7f4d1;
      padding-left: 8px;
      padding-right: 8px;

      display: flex;
      justify-content: center;
      align-items: center;

      &__struct {
        width: 97%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .ico {
          width: 20px;
          margin-right: 0.2em;
          transform: translateY(-2%);
        }

        .text {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          color: var(--green);
          font-size: 0.8rem;
          padding-top: 3px;
          width: calc(100% - 20px);
        }
      }



    }

    .info {
      height: 2.3rem;
      width: 2.3rem;
      border-radius: 50%;
      background-color: #f5f5f5;
      margin-left: 0.3em;
      display: grid;
      place-items: center;

      svg {
        width: 50%;
      }
    }
  }
}

.bar_search {
  margin-top: 15px;
  width: 100%;
  position: relative;
  display: grid;
  place-items: center;

  .input_search {
    width: 100%;
    height: 3.1625rem;
    flex-shrink: 0;

    border-radius: 2em;
    border: 1px solid #e7f4d1;
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);

    display: grid;
    place-items: center;

    .input_search_containt {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 93%;

      input {
        background: none;
        border: none;
        font-family: "Acorn_r";
        width: 80%;
      }

      .ico {
        width: 2.4375rem;
        height: 1.9375rem;
        border-radius: 0.96875rem;
        background: var(--green);

        display: grid;
        place-items: center;

        border: none;

        svg {
          width: 100%;
        }
      }
    }

    z-index: 1;
  }

  .image_3d {
    position: absolute;
    bottom: 0;
    right: 0px;
    width: 35%;
    transform: translateX(-2%);
    z-index: 0;

    @media all and (min-width: 400px) {
      width: 25%;
    }
  }
}

.part_title {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 20px;
  margin-bottom: 8px;
}

.categories {
  display: block;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 10px;
  margin-top: 1em;

  .categories_container {
    display: flex;
    width: max-content;
    gap: 0.3em;

    .category {
      width: auto;
      padding: 0 15px;
      height: 2.7rem;
      flex-shrink: 0;
      display: grid;
      place-items: center;
      border-radius: 2.5rem;
      line-height: 1em;
      border: 1px solid rgba(0, 0, 0, 0.2);
      cursor: pointer;

      &:first-child {
        margin-left: 3%;
      }

      &:last-child {
        margin-right: 40px;
      }
    }

    .active {
      background: var(--orange);
      border: none;
      color: var(--white);
      font-weight: 600;
    }
  }
}

.menu_list {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: 100%;
  margin-top: 0.5em;
  padding-bottom: 2em;


  @media all and (min-width : 800px) {
    flex-direction: row;
    flex-flow: row wrap;
    align-items: stretch;
  }



  .menu {
    width: 100%;
    height: 8em;

    border-radius: 26px;
    background: #fff;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.17);
    // overflow: hidden;
    display: grid;
    place-items: center;

    @media all and (min-width : 800px) {
      width: 320px;
      flex-grow: 1;
    }



    .menu_struct {
      // width: 96% !important;
      // height: 84%;

      // display: flex;
      // justify-content: space-between;

      // gap: 0.5em;

      width: 100% !important;
      // width:calc(100% + ( 0.7em + 0.7em)) !important
      height: 84%;
      display: flex;
      justify-content: space-between;

      .menu_img {
        height: 6.7em;
        width: 35%;
        overflow: hidden;
        border-radius: 1.2rem;
        margin-left: 0.6em;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .menu_info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 65%;
        margin-left: 0.6em;
        margin-right: 0.6em;

        &__name {
          font-size: 1em;
          font-weight: bold;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }

        &__description {
          font-size: 0.6em;
          // line-height: 1.25em;
          color: #7e7e7e;
          margin-top: 0.3em;
          opacity: 0.8;
          word-spacing: 0.15em;

          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;

          // @media all and (max-width: 530px){
          //   -webkit-line-clamp: 3;
          // }
          @media all and (max-width: 460px) {
            -webkit-line-clamp: 2;
          }
        }

        &__price {
          display: block;
          font-weight: bold;
          color: var(--orange);
          margin-top: 0.4em;
        }

        .menu_plus {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 98%;
        }
      }
    }
  }
}

.center_container {
  display: grid;
  place-items: center;
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}

.menu_preparation_time {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  // padding-left: 5px;
  // padding-right: 5px;

  width: auto;
  height: 0.305rem;
  flex-shrink: 0;

  border-radius: 20px;
  background: #f6f6f6;

  svg {
    width: 1em;
  }

  .text {
    font-size: 0.7em;
    line-height: 1em;
    transform: translateY(17%);
    margin-left: 0.3em;
  }
}

.menu_user_view {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2em;

  svg {
    width: 1.1em;
    height: auto;
    transform-origin: center;
    // transform: scale(.5);
    display: inline-block;
    // transform: translateY(-2px);
    color: #7e7e7e;
  }

  .text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;
    line-height: 1em;
    color: #7e7e7e;
    padding-top: 2px;

    span {
      font-weight: 300;
    }
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 6.6875rem;
  height: 2.4375rem;
  flex-shrink: 0;

  border-radius: 1.21875rem;
  border: 1px solid var(--orange);
  background: #fff;
  cursor: pointer;
  color: var(--orange);

  .text,
  svg {
    display: inline-block;
  }

  svg {
    margin-left: 0.5em;
  }
}

.table_box {
  position: absolute;
  top: 20%;
  right: 0px;

  width: 1.8em;
  height: auto;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  flex-shrink: 0;
  text-align: center;
  border-radius: 0.5em 0em 0em 0.5em;
  background: #73b704;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);

  color: var(--white);
  writing-mode: vertical-lr;
  font-size: 0.9em;
  line-height: 1.5em;
  text-transform: uppercase;
}

.panier_ico {
  position: fixed;
  top: 3%;
  right: 5%;
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  z-index: 2;
  border-radius: 1rem;
  background: #f7efea;
  box-shadow: 0px -2px 7px 0px rgba(0, 0, 0, 0.651);
  display: grid;
  place-items: center;

  svg {
    width: 60%;
  }

  &:after {
    content: attr(item);
    position: absolute;
    top: 0;
    right: 0%;
    transform: translateY(-50%);
    width: 0.875rem;
    height: 0.875rem;
    flex-shrink: 0;
    border-radius: 50%;
    background: var(--orange);
    color: var(--white);
    font-size: 0.5em;
    display: grid;
    place-items: center;
  }
}

.rounded_btn {
  display: grid;
  place-items: center;

  border-radius: 50%;
  width: 63px;
  height: 63px;
  box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.25);
}

.AboutResto {
  display: grid;
  place-items: center;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 50;
  background: rgba(0, 0, 0, 0.603);

  * {
    transition: all 0.3s ease;
  }

  .layerOff {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .action_close {
    position: fixed;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
    color: var(--orange);
    background: var(--white);
  }

  .aboutStruct {
    display: block;

    width: 80vw;
    height: auto;
    border-radius: 27px;
    background: white;
    overflow: hidden;
    position: relative;
    z-index: 10;
    transition: all 0.3s ease;

    .aboutImg {
      width: 100%;
      border-radius: 27px;
      height: 260px;
      overflow: hidden;
      position: relative;
      object-fit: cover;
      transition: 0.3s;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s;
      }
    }

    .aboutText {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: 0.3s;
      width: 100%;

      &__struct {
        width: 90%;
        display: block;
        // border: 1px solid black;
        transition: 0.3s;

        h2 {
          color: var(--orange);
          margin: 0px 0px;
          margin-top: 15px;
          font-size: 1.5em;
          line-height: 1em;
          font-weight: 600;
          margin-bottom: 0.7em;
        }

        div {
          pre {
            color: var(--black);
            text-align: justify;
            margin-top: 5px;
            font-size: 0.9em;
            font-family: "Acorn_r";
            width: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            white-space: pre-wrap;
            transition: 0.3s;
          }
        }

        .btn_read {
          width: 100%;
          display: grid;
          place-items: center;
          margin-bottom: 30px;
          margin-top: 10px;
          font-family: "Acorn_r";
        }
      }
    }
  }
}

.link {
  text-decoration: none;
  color: inherit;
}

.btnRead {
  display: grid;
  place-items: center;
  background: var(--orange);
  border-radius: 18.5px;
  width: 60%;
  height: 3em;
  color: var(--white);
  border: none;
  font-size: 1em;
  font-family: "Acorn_r";
}

.menuDetails {
  background: none;

  * {
    transition: all 0.3s ease;
  }



  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }


  .menu_img {
    display: block;
    width: 100%;
    overflow: hidden;
    height: 45vh;
    position: relative;

    .img {
      width: 100%;
      height: 100%;
      position: relative;
      transition: display 0.5s ease-in-out;
      overflow: hidden;
      // background-image: url("https://storage.googleapis.com/uploadregestfiles/assets/lazy500.gif");
      background: linear-gradient(-45deg, black, white, black);
      background-size: 400% 400%;
      animation: gradient 1s ease infinite;


      img {
        // position:static;
        // left: 0;
        // top: 0;
        width: 100%;
        height: 100%;
        transition: all 0.3s ease;
        //  height: fit-content;
        object-fit: cover;
        // transition: display 0.5s ease-in-out;
        transform-origin: top center;
        transform: scale(1);
      }

      &:last-child {
        opacity: 0;
        display: none;
      }
    }
  }

  .menuInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: var(--white);
    width: 100%;
    border-radius: 31px 31px 31px 31px !important;
    position: relative;
    transform: translateY(-50px);
    padding-top: 20px;

    .actionMenuPage {
      position: absolute;
      right: 0;
      top: 0;
      transform: translateY(-80%);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      z-index: 10;

      .btn_share {
        background: var(--orange);
        cursor: pointer;
      }

      .btn_close {
        background-color: var(--white);
        cursor: pointer !important;
      }
    }

    &__struct {
      display: block;
      width: 90%;
      position: relative;
      // border: 1px solid;

      .menu_stat {
        display: flex;
        gap: 10px;
        justify-content: flex-start;
        align-items: center;
      }

      .menu_title {
        color: var(--orange);
        text-align: left;
        font-size: 1.875em;
        line-height: 1em;
        font-weight: 600;
        margin: 0 0;
        margin-top: 0.5em;
      }

      .inputPrecise {
        width: 100%;
        position: relative;
        height: auto;
        border: 1px solid var(--orange);
        border-radius: 12px;
        margin-top: 1em;
        margin-bottom: 1.2em;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;

        .label {
          position: absolute;
          top: 0;
          left: 4%;
          transform: translateY(-50%);
          background-color: var(--orange);
          height: auto;
          padding: 5px 12px;
          color: white;
          border-radius: 20px;
          font-size: .85em;
          line-height: 1em;
        }

        input {
          margin-top: 1em;
          margin-bottom: .4em;
          width: 91%;
          background: none;
          color: var(--orange);
          outline: none;
          border: 0px solid;

          &::placeholder {
            color: #000000 !important;
            opacity: .37 !important;
          }
        }

      }



      .acountable {
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
        margin-top: 1em;
        width: 100%;

        @media all and (max-width: 370px) {
          flex-wrap: wrap;
          align-items: start;
        }

        .pricing {
          .price {
            display: block;
            position: relative;
            font-size: 2.1875em;
            font-weight: 600;

            &::after {
              content: attr(device);
              position: absolute;
              top: 0px;
              right: 0;
              transform: translateX(100%) translateY(-50%);
              font-size: 0.4em;
            }
          }
        }

        .form {
          display: flex;
          gap: 10px;
          justify-content: center;
          align-items: center;

          .input_box {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            border-radius: 22px;
            border-style: solid;
            border-color: rgba(126, 126, 126, 0.3);
            border-width: 1px;
            width: 5.5em;
            height: 2.75em;

            .btnInput {
              font-size: 0.8em;
              font-weight: 600;
              display: grid;
              place-items: center;
              border-radius: 25px;
              width: 15px;
              height: 15px;
              line-height: 1.3em;
            }

            .subtract {
              background: #f7efea;
              color: var(--orange);
            }

            .add {
              background: #e7f4d1;
              color: #73b704;
            }
          }

          .addToBasket {
            background: #73b704;
            border-radius: 22px;
            width: 79px;
            height: 44px;
            position: relative;
            color: var(--white);
            display: grid;
            place-items: center;
          }
        }
      }

      .MenuDescription {
        padding-top: 1em;
        margin-top: 1em;
        border-top: 1px solid rgba(204, 204, 204, 0.5);
        color: #7e7e7e;
        padding-bottom: 73px;
        text-align: justify;
        font-size: 0.9em;
        font-family: "Acorn_r";
        width: 100%;
        white-space: pre-wrap;
        transition: 0.3s;
      }
    }
  }
}

.MenuDetailsPop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  z-index: 50;
  background: white;
}

.box_btn_command {
  background: #f7efea;
  border-radius: 36.5px;
  border-style: solid;
  border-color: transparent;
  border-width: 1px;
  height: 73px;
  position: fixed;
  bottom: 4%;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.252);
  width: 90%;
  display: grid;
  place-items: center;

  * {
    transition: all 0.3s ease;
  }

  &__struct {
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .totalPanier {
      display: block;

      .text {
        font-size: 0.75em;
        font-weight: 500;
      }

      .total {
        font-size: 1.875em;
        line-height: 1em;
        font-weight: 600;
        position: relative;

        &::after {
          content: attr(devise);
          position: absolute;
          top: 0px;
          right: 0;
          transform: translateX(100%) translateY(-50%);
          font-size: 0.4em;
        }
      }
    }

    .btn_command {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background-color: var(--orange);
      border-radius: 22px;
      width: 8.875em;
      height: 2.75em;
      color: var(--white);
      font-family: "Acorn_r" !important;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.37);

      .text {
        font-size: 0.9em !important;
      }

      .ico {
        width: 1.0625em;
      }
    }
  }
}

.greenBtn {
  background: #e7f4d1;

  .btn_command {
    background-color: var(--green);
  }
}

.btn_close_panier {
  position: fixed;
  top: 3%;
  right: 3%;
  z-index: 50;
  background: white;
  cursor: pointer;
  width: 40px;
  height: 40px;

  svg {
    width: 30%;
  }
}

.Checkout {
  .CheckoutpanierBox {
    z-index: 1 !important;

    .panierBox__struct {
      h4 {
        font-size: 1.3em;
      }
    }
  }

  .panierListCheckout {
    margin-top: 1em !important;

    .panierList__struct {
      .formCheckout {
        display: flex;
        flex-direction: column;
        gap: 1.5em;
        font-family: "Acorn_r";

        margin-top: 1em;

        .inputBox {
          border-radius: 20.5px;
          border-style: solid;
          border-color: var(--black);
          border-width: 1px;
          height: 2.8625em;
          width: 100%;
          position: relative;

          display: grid;
          place-items: center;

          .label {
            position: absolute;
            left: 5%;
            top: 0;
            font-family: "Acorn_r" !important;
            display: inline-block;
            transform: translateY(-50%);
            background: white;
            padding-left: 7px;
            padding-right: 15px;
            color: var(--black);

            &::after {
              position: absolute;
              top: 0;
              right: 10px;
              transform: translateX(100%);
              color: var(--orange);
              content: attr(attr);
            }
          }

          input,
          textarea {
            background: none;
            border: none;
            color: var(--black);
            font-family: "Acorn_r" !important;

            width: 85%;
          }

          textarea {
            padding-top: 15px;
          }

          input:focus,
          textarea:focus {
            color: var(--orange) !important;
            border-color: var(--orange) !important;
            font-weight: bold;

            &::placeholder {
              color: var(--orange) !important;
              opacity: 0.3;
            }
          }
        }

        .inputBox:hover:not(.optionOrder) {
          color: var(--orange) !important;
          border-color: var(--orange) !important;

          &::placeholder {
            color: var(--orange) !important;
            opacity: 0.3;
          }

          .label {
            color: var(--orange);
          }
        }

        .textareaBox,
        .optionOrder {
          height: auto;
        }

        .optionOrder {
          margin-bottom: 0;

          &__struct {
            width: 90%;
            padding-top: 15px;
            padding-bottom: 10px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.5em;

            input {
              width: auto;
              display: block;
              accent-color: var(--orange);
            }

            .radio {
              display: flex;
              align-items: baseline;
              gap: 0.2em;
            }
          }
        }

        .notice {
          background: #d52d1a;
          border-radius: 17px;
          height: auto;
          width: 100%;
          position: relative;

          display: grid;
          place-items: center;

          &__struct {
            width: 90%;
            height: auto;
            margin-top: 12px;
            margin-bottom: 12px;

            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            svg {
              display: none;
            }

            .text {
              color: #ffffff;
              text-align: left;
              font-size: 1em;
              line-height: 1.3em;
              width: 93%;
            }
          }
        }
      }
    }
  }
}

.CheckoutPop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  z-index: 50;
  background: white;
}

.Finalize {
  display: grid;
  place-items: center;

  * {
    text-align: center;
  }

  .main_struct {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .image {
    display: block;
    width: 80%;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .container {
    display: block;
    width: 100%;

    h2 {
      color: var(--green);
      font-size: 2.2em;
      line-height: 1.1em;
      margin-top: 0.5em;
      font-weight: bold;
      margin-bottom: 0.5em;
    }

    p {
      color: #7e7e7e;
      font-size: 1em;
      margin-bottom: 1em;
    }

    .timer {
      display: block;

      .title {
        font-size: 0.7em;
      }

      .timer_value {
        font-size: 10vw;
        color: var(--orange);
        font-weight: bold;
      }
    }

    .bnt {
      background: var(--orange);
      border-radius: 60px;
      width: 100%;
      height: 70px;
      color: white;
      display: grid;
      place-items: center;
      margin-top: 2em;
    }
  }
}

.FinalizePop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  z-index: 50;
  background: white;
}

button {
  border: none;
}

.alert_container {
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.355);

  display: grid;
  place-items: center;

  transform: translateY(100%);
  transition: 1s ease;

  .alert_container_layer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .alert_box {
    display: grid;
    place-items: center;
    width: 17em;
    min-height: 18em;
    border-radius: 20px;
    color: var(--black);
    background: var(--white);
    border: 1.8px solid transparent;
    position: relative;
    z-index: 2;
    transition-delay: 1.4s;
    transform: scale(0);

    .struct {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: 90%;

      svg {
        transform-origin: center;
        zoom: 180%;
      }

      .alert_text {
        font-size: 2em;
      }

      .alert_status {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .alert_status_indicator {
          text-align: center;
          margin-top: 0.5em;
          margin-bottom: 0.5em;
          font-size: 1.2em;
        }

        .alert_status_text {
          margin-top: 0.5em;
          cursor: pointer;
        }
      }
    }
  }

  .success {
    $success: #00b031;
    border-color: $success;

    .alert_text {
      color: $success;
    }

    .alert_status_text {
      background: $success;
      color: white;
      display: grid;
      place-items: center;
      border-radius: 50px;
      line-height: 1em;
      width: 5em;
      height: 1.8875em;
    }
  }
}

.alert_container_active {
  transition: 1s ease;
  transform: translateY(0%);

  .alert_box {
    transition-delay: 1.4s;
    transform: scale(1);
  }
}

div:where(.swal2-container) div:where(.swal2-popup) {
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 20em !important;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 27px !important;
  background: #fff;
  color: #545454;
  font-family: inherit;
  font-size: 1rem;
}

div:where(.swal2-container) button:where(.swal2-styled) {
  margin: 0.3125em;
  padding: 0.625em 3.1em !important;
  transition: box-shadow 0.1s;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0);
  font-weight: 500;
  border-radius: 50px !important;
  background-color: var(--orange) !important;
}

div:where(.swal2-container) h2:where(.swal2-title) {
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: 0 !important;
  color: inherit;
  font-size: 1.875em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}

/* Loader Styles */
.loader {
  z-index: 100;
  top: 0;
  left: 0;
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.788);
  animation: fadingBg 0.5s linear infinite;

  .spin {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid var(--orange);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
  }
}

.loader2 {
  display: none;
  place-items: center;
  background: white;
  animation: none;
  animation: fadingBg 0.5s linear infinite;

  .layer {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0vh;
    width: 100%;
    background: var(--orange);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: var(--white);
    font-size: 2em;
    z-index: 3;

    .counterLoader {
      text-shadow: 2px 0 var(--orange), -2px 0 var(--orange), 0 2px var(--orange), 0 -2px var(--orange),
        1px 1px var(--orange), -1px -1px var(--orange), 1px -1px var(--orange), -1px 1px var(--orange);

    }
  }

  .container_establisment {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
    z-index: 5;
  }

  .icon_log {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h5 {
    color: var(--white);
    text-align: center;
    margin-top: .5em;
    font-size: 1.5em;
    font-weight: 600;
    text-shadow: 2px 0 var(--orange), -2px 0 var(--orange), 0 2px var(--orange), 0 -2px var(--orange),
      1px 1px var(--orange), -1px -1px var(--orange), 1px -1px var(--orange), -1px 1px var(--orange);

  }

}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadingBg {
  0% {
    background: rgba(255, 255, 255, 0.445);
  }

  50% {
    background: rgba(255, 255, 255, 0.658);
  }

  100% {
    background: rgba(255, 255, 255, 0.445);
  }
}

/* Hide content initially */
.hidden {
  display: none;
}




.order-ticket {
  font-family: Arial, sans-serif;
  max-width: 300px;
  margin: 0 auto;
  // border: 1px solid #ccc;
  padding: 20px;
  background: #ffffff;
  font-size: .88em;

  // * {
  //   font-weight: 900;
  // }


  .ticket {
    text-align: center;
  }

  .center-text {
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
  }

  table {
    width: 100%;
    margin: 20px 0;
    border-collapse: collapse;
  }

  table th,
  table td {
    text-align: left;
    padding: 5px 0;
  }

  table th {
    border-bottom: 1px solid #000;
  }

  .totals {
    text-align: right;
  }

  .totals p {
    margin: 5px 0;
  }

  .barcode {
    margin-top: 20px;
    text-align: center;
  }


  button:hover {
    background-color: #444;
  }
}

.printTicketBTN {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
  width: 100%;
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
}

.addonSection {
  background: #E7F4D1;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 12px;
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  overflow-y: hidden;

  .struct {
    width: 90%;


    .headers {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;

      .title {
        font-size: 20px;
        color: var(--green);
      }

      .action {
        background: white;
        border-radius: 50%;
        display: grid;
        place-items: center;
        padding: 4px;
        cursor: pointer;

        svg {
          color: var(--green);
          width: 25px;
        }
      }

    }


    .containerAddon {
      margin: 10px 0;
      width: 80vw;
      overflow-x: auto;
      padding-bottom: 10px;
    }


    .addons {
      display: flex;
      gap: 6px;
      width: max-content;
      padding-right: 15px;


      .addon {
        background: #ffffff;
        border-radius: 17px;
        width: 120px;
        height: auto;
        padding: 10px;
        display: inline-block;
        cursor: pointer;

        .img {
          overflow: hidden;
          border-radius: calc(17px - 10px);

          img {
            width: 100%;
          }
        }

        .addon_name {
          margin: 5px 0;
          color: var(--orange);
        }


        &:hover {
          background: var(--orange);

          .addon_name {
            color: var(--white);
          }
        }


      }

      .selectedAddon {
        background: var(--orange);

        .addon_name {
          color: var(--white);
        }
      }
    }

  }
}



.global_inputContainter {
  .input {
    border-radius: 6px;
    border-style: solid;
    border-color: #2c2c2c7a;
    border-width: 2px;
    height: 53px;
    position: relative;
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0px;
    }

    label {
      display: block;
      position: absolute;
      left: 10px;
      top: 0px;
      transform: translateY(-50%);
      font-size: 1em;
      background: #F4F4F4;
      padding-left: 10px;
      padding-right: 10px;

      sup {
        color: var(--orange);
      }
    }

    textarea,
    select,
    input {
      width: calc(100% - 40px);
      height: 100%;
      border: none;
      background: none;
      font-size: 1em;
      padding-left: 20px;
      font-family: inherit;
    }

    textarea {
      padding-top: 1.5em;
    }

    .image {
      width: 100%;
      height: 100%;
      position: relative;
      border-radius: 12px;

      display: flex;
      align-items: flex-end;
      justify-content: center;

      img {
        width: 90%;
        height: 80%;
        transform: translateY(-10%);
        border-radius: 3px;
        object-fit: cover;
        opacity: 0;
        z-index: 3;
        position: relative;
        transition: 0.3s;
      }

      .active {
        opacity: 1;
        transition: 0.3s;
      }

      svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
      }
    }

    .eye {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      display: grid;
      place-items: center;

      svg {
        width: 20px;
        color: var(--black);
      }

      .bar_hide {
        position: absolute;
        top: 50%;
        left: 0%;
        transform: rotate(45deg) translate(-8%, 136%);
        width: 26px;
        height: 1.5px;
        background-color: var(--black);
        border-radius: 20px;
      }
    }
  }

  .inputTextarea {
    height: auto;

    margin-top: 1em;
  }

  .inputFile {
    height: 200px;
  }
}
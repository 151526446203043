@font-face {
    font-family: 'Helvetica Neue';
    src: url('../font/HelveticaNeue-Roman.woff2') format('woff2'),
        url('../font/HelveticaNeue-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

html {
    scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 auto;
}

.light {
    color: #ff6b01;
    background: #ff6b0124;
    padding: 0 .1em;
    border-radius: 10px;
}

.color {
    color: #ff6b01;
}

.containergroupBtn {
    display: block;


    @media all and (max-width:380px) {
        display: grid;
        place-items: center;
    }
}

.groupBtn {
    display: flex;
    gap: 2em;
    position: relative;
    margin-top: 2em;


    @media all and (max-width: 500px) {
        gap: 1em;
        // justify-content: center;
        // align-items: stretch;
    }

    @media all and (max-width:380px) {
        flex-direction: column;
        gap: .5em;
        width: 80%;
        margin-top: 0em;
    }

    .deco {
        position: absolute;

        @media all and (max-width: 1080px) {
            z-index: -1;
        }
    }

    .star_green {
        right: 25%;
        transform: translateX(0%) translateY(-50%);
        width: 1.5em;

        @media all and (max-width: 1080px) {
            right: 0%;
        }
    }

    .star_orange {
        left: 0;
        transform: translateX(100%) translateY(150%);
        width: 4em;

        @media all and (max-width: 500px) {
            transform: translateX(20%) translateY(150%);
        }


    }

    .courbe {
        right: 0;
        transform: translateX(100%) translateY(150%);
        width: 6em;

        @media all and (max-width: 1080px) {
            display: none;
        }
    }
}

.btncta {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ff6b01;
    border-radius: 3.25em;
    color: white;
    padding: .6em 2em;
    font-size: 1.2em;
    gap: 10px;
    border: 1px solid #ff6b0100;

    @media all and (max-width: 500px) {
        padding: 0.3em 1em;
    }

    &:hover {
        color: #ff6b01;
        background: rgba(255, 255, 255, 0);
        border: 1px solid #ff6b01;

        svg path {
            fill: #ff6b01;
        }
    }
}

.btnctaWhite {


    color: white;
    background: rgba(255, 255, 255, 0);
    border: 1px solid white;

    svg path {
        fill: white;
    }

    &:hover {
        background: white;
        color: #ff6b01;

        svg path {
            fill: #ff6b01;
        }
    }
}

.btngreen {
    color: green;
    border: 1px solid green;
    background: transparent;
}


.Landing {
    display: flex;
    // align-items: center;
    flex-direction: column;
    font-family: 'Helvetica Neue';
    background: #ff6b01;
    overflow-x: hidden;
    scroll-behavior: smooth;

    a {
        text-decoration: none;
    }

    .intro {
        width: 100%;
        background: #FAFAFA;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        overflow: hidden !important;



        .courbe_end {
            width: 100%;
            position: relative;
            transform: translateY(1px);
            margin-top: 2em;
        }



        .linearGrid {
            display: flex;
            width: 80%;
            height: 100%;
            justify-content: space-between;
            position: absolute;
            z-index: 0;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            gap: calc(100% / 7);
            opacity: .7;

            @media all and (max-width:1080px) {
                width: 90%;
            }

            div {
                height: 100%;
                width: 1vw;

                opacity: .6;

                @media all and (max-width: 500px) {
                    opacity: .35;
                    width: 5vw;
                }

                & {
                    border-left: 1px solid #D1D4CB;
                    border-right: 1px solid #D1D4CB;

                    &:first-child {
                        border-right: none;
                        width: 0px;
                    }

                    &:last-child {
                        border-left: none;
                        width: 0px;
                    }
                }



            }
        }
    }

    .lstruct {
        width: 80%;

        @media all and (max-width: 1080px) {
            width: 90%;
        }
    }

    $headerHeight: 5em;

    .header {
        z-index: 10;
        height: $headerHeight;
        display: grid;
        place-items: center;
        position: fixed;

        .btnMob {
            display: none;
            background: #ff6b01;
            padding: .5em;
            padding-top: .15em;
            padding-bottom: .15em;
            border-radius: 8px;
            cursor: pointer;

            svg {
                width: 1.5em;
            }

            @media all and (max-width:1080px) {
                display: inline-block;
            }
        }


        .cta_BTN {
            @media all and (max-width:500px) {
                display: none;
            }
        }


        .lstruct {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
        }

        nav {
            ul {
                list-style-type: none;
                padding-left: 0;
                margin: 0;

                li {
                    display: inline-flex;
                    margin-left: 10px;
                    margin-right: 10px;
                    font-size: 1.2em;
                    cursor: pointer;

                    @media all and (max-width:1300px) {
                        font-size: .9em;
                    }

                    &:hover {
                        color: #ff6b01;
                    }

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                }
            }


        }

        nav {
            @media all and (max-width:1080px) {
                display: none;
                position: absolute;
                top: $headerHeight;
                right: 0%;
                padding-inline: 1em;
                background-color: #ff6b01;
                color: white;
                // transform: translateY(50%);
                z-index: 100;
                border-radius: 1em;
                box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.35);
                border: 3px solid;
                padding-top: 1em;
                padding-bottom: .2em;
                box-sizing: border-box;

                ul {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    // gap: .5em;

                    li {
                        display: block;
                        padding-inline: 0em;
                        margin-inline: 0em;
                        text-align: left;
                        border-bottom: 1px solid;
                        width: calc(100%);
                        padding-top: .5em;
                        padding-bottom: .5em;
                        font-size: 1em;
                        margin-top: 0;
                        margin-bottom: 0;

                        &:first-child {
                            margin-top: 0em;
                        }

                        &:last-child {
                            margin-bottom: 0em;
                            border-bottom: 0px solid;
                        }

                        &:hover {
                            color: white;
                        }

                        &:active {
                            color: #ff6b01;
                            background: white;
                        }
                    }
                }
            }
        }

        width: 100%;

        .logo {
            width: 10em;

            img {
                width: 100%;
            }
        }
    }


    .introContentSection {
        margin-top: 8em;

        @media all and (max-width: 500px) {
            margin-top: 6em;
        }

    }

    .introContent {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media all and (max-width: 1080px) {
            flex-direction: column;
        }

        @media all and (max-width: 380px) {
            text-align: center;
        }



        .textSide {
            width: 50%;
            position: relative;

            @media all and (max-width: 1080px) {
                width: 100%;
            }

            // @media all and (max-width: 500px) {
            //     text-align: center;
            // }

            .star_outline {
                top: -20px;
                position: relative;
            }

            .littleText {
                color: #ff6b01;
                font-weight: 500;
                font-size: 1.3em;
                margin-bottom: .5em;

                @media all and (max-width: 1080px) {
                    font-size: 4vw;
                }
            }

            h2 {
                font-size: 4vw;
                line-height: 1.1em;
                color: #213856;
                font-weight: 700;

                @media all and (max-width: 1080px) {
                    font-size: 8vw;
                }

                @media all and (max-width: 500px) {
                    font-size: 9vw;
                }


            }

            p {
                color: #585858;
                margin-top: 1em;
                font-size: 1.5em;


                @media all and (max-width: 1600px) {
                    font-size: 1.2em;
                }

                @media all and (max-width:1300px) {
                    font-size: .9em;
                }

                @media all and (max-width: 1080px) {
                    font-size: 3.5vw;
                    line-height: 1.25em;
                }
            }
        }

        .imageSide {
            position: relative;

            @media all and (max-width: 1080px) {
                margin-top: 5em;
                margin-bottom: 5em;
            }

            @media all and (max-width: 500px) {
                margin-top: 1em;
                margin-bottom: 3em;
            }

            .star_green_03 {
                position: absolute;
                right: 3%;
                width: 10em;
                transform: translateY(50%);
                top: 0;
                z-index: 1;

                animation: rotate 10s infinite;
            }

            .imageContainer {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 2;

                .asideImg {
                    width: 10vw;

                    @media all and (max-width: 1080px) {
                        width: 25vw;
                    }

                }

                .right {
                    transform: translateX(20%);

                }

                .left {
                    transform: translateX(-30%) translateY(40%);
                }

                .mainImage {
                    width: 16vw;

                    @media all and (max-width: 1080px) {
                        width: 50vw;
                    }

                    img {
                        width: 100%;
                    }
                }
            }

        }

    }


}



@keyframes rotate {

    0%,
    100% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(3turn);
    }
}



.tag {
    padding: .5em .8em;
    border-radius: 30em;
    width: auto;
    display: inline-block;
    font-weight: 600;
}

.centerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    h2 {
        font-size: 4vw;
        line-height: 1em;
        text-align: center;
        color: white;
        width: 40vw;
        margin-top: .3em;
        margin-bottom: .3em;
        font-weight: 600;

        @media all and (max-width: 1080px) {
            font-size: 6vw;
            width: 70%;
        }

        @media all and (max-width: 600px) {
            font-size: 10vw;
            width: 100%;
        }
    }

    .totalAmountMonth {
        font-size: 4em;

        @media all and (max-width: 1080px) {
            font-size: 8vw;

        }
    }

    .title {
        font-size: 1.2em;

        @media all and (max-width: 500px) {
            font-size: 3.5vw;
        }
    }
}


.containerCarousel {
    gap: 12px;
    display: flex;
    gap: 1em;

}

.establisment {
    border-radius: 3em;
    background: white;
    width: 40em;
    padding: 1em;
    display: inline-flex;
    gap: 1em;

    @media all and (max-width: 1080px) {
        width: 75vw;
    }

    @media all and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
    }

    &:first-child {
        margin-left: 10vw;
    }

    &:last-child {
        margin-right: 10vw;
    }

    .img {
        width: 40%;
        height: 15em;
        border-radius: 2.5em;
        overflow: hidden;
        position: relative;

        @media all and (max-width: 600px) {
            width: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .detail {
        width: 55%;

        @media all and (max-width: 600px) {
            width: 97%;
            padding-bottom: 2em;
        }

        h4 {
            font-size: 1.5em;
            line-height: 1em;
            font-weight: 600;
        }

        h6 {
            font-size: 1em;
            font-weight: 600;
            margin-top: 0;
            line-height: 1em;
        }

        p {
            font-size: .9em;
            line-height: 1.2em;
            color: #585858;
            margin-top: 1.5em;
            white-space: wrap !important;
        }

        .price {
            color: green;
            margin-top: .5em;

            .value {

                font-size: 2em;
                font-weight: 600;
                line-height: 1em;
            }
        }
    }

}







.holster {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: column nowrap;
    width: calc(100%);
}

.container {
    width: 100%;
    display: flex;
    flex: none;
    overflow: auto;
    gap: 10px;
}

.container.x {
    width: 100%;
    flex-flow: row nowrap;

}

.container.y {
    width: 256px;
    height: 256px;
    flex-flow: column nowrap;
    overflow-x: hidden;
}

/* scroll-snap */
.x.mandatory-scroll-snapping {
    scroll-snap-type: x mandatory;
}

.x.proximity-scroll-snapping {
    scroll-snap-type: x proximity;
}

.y.mandatory-scroll-snapping {
    scroll-snap-type: y mandatory;
}

.y.proximity-scroll-snapping {
    scroll-snap-type: y proximity;
}

.container>div {
    scroll-snap-align: center;
    flex: none;
}

.x.container>div {}


/* appearance fixes */
.y.container>div:first-child {
    line-height: 1.3;
    font-size: 64px;
}


* ::-webkit-scrollbar {
    scrollbar-width: 0;
    width: 0;
}


.whiteSection {
    padding: 5em;
    padding-top: 5em;
    padding-bottom: 5em;
    background: white;
    border-radius: 1.5em;

    @media all and (max-width: 500px) {
        padding: 1.5em;
    }

    h1 {
        width: 70%;
        text-align: center;
        font-size: 3vw;
        margin-top: .5em;

        @media all and (max-width: 1080px) {
            width: 90%;
            font-size: 5vw;
        }

        @media all and (max-width: 500px) {
            font-size: 7vw;
            width: 100%;
        }
    }
}

.cardImg_container {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    margin-top: 4em;


}

.cardImg {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    border-radius: 12px;
    border: 1px solid #585858;
    gap: 20px;
    width: 30%;

    flex-grow: 1;

    // @media all and (max-width: 1800px) {
    //     width: 45%;
    // }

    @media all and (max-width: 900px) {
        width: 100%;
    }

    @media all and (max-width: 500px) {
        flex-direction: column;
        gap: 10px;
    }

    .text {
        font-size: 1.2em;
        width: calc(100% - 6em);

        @media all and (max-width: 500px) {
            width: 100%;
        }
    }

    .img {
        width: 6em;
        height: 6em;
        overflow: hidden;
        border-radius: .5em;

        @media all and (max-width: 500px) {
            width: 100%;
            height: 15em;
        }

        // @media all and (max-width: 1300px) {
        //     width: 50%;
        //     height: 300px;
        // }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }



}

.imgLogo {
    background: white;
    padding: 5px;
    border-radius: 20px;
    display: inline-block;
    padding-inline: 30px;
    transform: rotate(4deg);

    img {
        width: 200px;

        @media all and (max-width: 1080px) {
            width: 4em;
        }
    }
}

.outlineText {
    color: white;
    font-size: 4em;
    text-align: center;
    width: 60%;

    @media all and (max-width: 1600px) {
        width: 100%;
    }

    @media all and (max-width: 1080px) {
        font-size: 7vw;
    }

}

.outlineTextCTA {
    color: #213856;
    margin-top: .5em;
}

.flexcontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3em;
    gap: 2em;

    .itemphone {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 20%;

        @media all and (max-width: 1080px) {
            width: 45%;
        }

        @media all and (max-width: 730px) {
            width: 100%;
        }

        .num {
            font-size: 3em;
            color: white;
            font-weight: 600;
        }

        .description {
            text-align: center;
            color: white;
            font-size: 1.4vw;
            width: 100%;
            margin-top: .5em;

            @media all and (max-width: 1080px) {
                font-size: 1.5em;
                font-weight: bold;
            }

            @media all and (max-width: 500px) {
                font-size: 6.4vw;
            }
        }

        .img {
            width: 70%;

            @media all and (max-width: 730px) {
                width: 50%;
            }

            img {
                width: 100%;
            }
        }

    }


}

.usageflexcontainer {
    align-items: flex-start;

    @media all and (max-width: 1080px) {
        flex-wrap: wrap;
        gap: 4em;
    }

}

.cardText {
    padding: 2em;
    width: 27%;
    border-radius: 1.5em;
    flex-grow: 1;

    @media all and (max-width: 800px) {
        padding: 1em;
        width: 100%;
    }

    .num {
        font-weight: 600;
        font-size: 3em;

    }

    .title {
        color: #ff6b01;
        margin-top: 1em;
        margin-bottom: 1.5em;
        font-size: 1.5em;
        font-weight: 600;

        @media all and (max-width: 1080px) {
            font-size: 1.5em;
            margin-top: .5em;
            margin-bottom: .5em;
        }
    }

    .description {
        font-size: 1.3em;
        color: #585858;

        @media all and (max-width: 1300px) {
            font-size: 1em;
            line-height: 1.3em;
        }

        @media all and (max-width: 1080px) {
            font-size: .85em;
            line-height: 1.3em;
        }
    }
}

.cardText_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1em;
    margin-top: 4em;


}


.fond1 {
    background: #F2F8FF;
}

.fond2 {
    background: #F2FFF8;
}

.fond3 {
    background: #FFFFDE;
}

.fond4 {
    background: #F7F2FF;
}

.fond5 {
    background: #E9FFE8;
}

.fond6 {
    background: #FFEFE9;
}




.cardPlan {

    padding: 3em 3em;
    background: white;
    border-radius: 2em;
    width: calc(30% - 7em);
    flex-grow: 1;

    @media all and (max-width: 1080px) {
        width: calc(100% - 7em);

    }

    .title {
        font-size: 2em;
        font-weight: 600;
        color: #213856;

        @media all and (max-width: 1450px) {
            font-size: 1.5em;
        }

    }

    .price {
        font-size: 2em;
        margin-top: .5em;
        margin-bottom: .5em;

        @media all and (max-width: 1450px) {
            font-size: 1.5em;
        }

        span {
            font-weight: 600;
        }
    }

    .launchBTN {
        width: calc(100%);
        padding: 0.5em 1em;
        background: #ff6b01;
        color: white;
        font-size: 2em;
        text-align: center;
        border-radius: 2em;
        cursor: pointer;

        margin: .45em 0;

        @media all and (max-width: 1450px) {
            font-size: 1.5em;
        }

    }

    ul {
        list-style-type: none;
        padding: 0;

        li {
            font-size: 1.2em;
            padding: .7em 0;
            border: 1px solid #D1D4CB;
            border-left: none;
            border-right: none;
            border-bottom: none;

            @media all and (max-width: 1450px) {
                font-size: 1em;
            }

            &:first-child {
                border-top: none;
            }

            // &:last-child {
            //     border-top: 0;
            // }
        }
    }

}

.dashboardimg {
    margin-top: 4em;

    img {
        border-radius: 1em;
        width: 100%;
    }
}


.courbe_end {
    position: relative;
    transform: translateY(2px);
}

.footerLanding {
    background: white;
    padding-bottom: 2em;

    .flexcontener {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 4em;
        margin-bottom: 4em;


        @media all and (max-width:1300px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 2em;
            text-align: center;
        }



        .about {
            width: 40%;

            @media all and (max-width:1300px) {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .logo {
                width: 15em;

                img {
                    width: 100%;
                }
            }

            p {
                font-size: 1.3em;
                text-align: left !important;
            }
        }

        .links {
            font-size: 1.3em;

            @media all and (max-width:1300px) {
                width: 100%;
            }

            ul {
                list-style-type: none;
                padding-left: 0;
                margin: 0;

                li {
                    padding: .3em 0;

                    &:first-child {
                        padding-top: 0;
                    }

                    &:last-child {
                        padding-bottom: 0;
                    }

                }
            }
        }

        .contacts {
            font-size: 1.3em;

            @media all and (max-width:1300px) {
                width: 100%;
            }

            div {
                margin-bottom: .5em;
                text-decoration: none !important;

                a {
                    text-decoration: none !important;
                    color: inherit;
                }

            }
        }
    }

    .copyright {
        background: #000;
        color: white;
        padding: 1.5em 2em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 1em;

        @media all and (max-width:900px) {
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;
            gap: 1em;
        }

        .ico {
            width: 2em;

            @media all and (max-width:1080px) {
                width: 1em;
            }


            svg {
                width: 100%;
            }
        }

        .logo {
            @media all and (max-width:900px) {
                display: none;
            }
        }

        .text {
            font-size: 1.2em;

            @media all and (max-width:900px) {
                text-align: center;
            }
        }

    }


    .socials {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.5em;

        .ico {
            width: 2em;

            svg {
                width: 100%;
            }

            &:nth-child(2) {
                width: 1.2em;
            }
        }
    }

}

.sectionGap {
    margin-bottom: 15vw;
    margin-top: 15vw;
}

.cardPlanflexcontainer {
    gap: 1em;
    align-items: flex-start;

    @media all and (max-width: 1080px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }
}

.footerLink {
    cursor: pointer;
}